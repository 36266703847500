import { VIEWLIO_ENV, viewlioConfig } from '@viewlio/config/src/viewlioConfig'

export const datadogRumScript = `
  if(typeof window !== undefined && window.location.origin && "${viewlioConfig.apiKeys.datadog.clientToken}") {
    (function(h,o,u,n,d) {
      h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
      d=o.createElement(u);d.async=1;d.src=n
      n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
    })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v4/datadog-rum.js','DD_RUM')

    DD_RUM.onReady(function() {
      DD_RUM.init({
        allowedTracingOrigins: [window.location.origin, ${/https:\/\/.*\.juul\.com/}],
        applicationId: "${viewlioConfig.apiKeys.datadog.applicationId}",
        clientToken: "${viewlioConfig.apiKeys.datadog.clientToken}",
        env: "${VIEWLIO_ENV}",
        sampleRate: 50,
        service: 'viewlio',
        sessionReplaySampleRate: 0,
        trackInteractions: true,
        version: "${viewlioConfig.gitSHA}",
      })
    })
  }
`
